import React, { useEffect, useState } from 'react';

interface ILoadingDelay {
	WaitComponent?: React.FC;
}

export const LoadingDelay: React.FC<ILoadingDelay> = props => {
	const { WaitComponent, children } = props;
	const [shouldRender, setShouldRender] = useState(false);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setShouldRender(true);
		}, 300);

		return () => {
			clearTimeout(timeout);
		};
	});

	if (WaitComponent && !shouldRender) {
		return <WaitComponent />;
	} else if (shouldRender) {
		return <>{children}</>;
	} else {
		return null;
	}
};
