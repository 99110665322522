import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginCredentials, LoginResult, useLogin } from 'api/auth';
import { useClearCache } from 'api/api';

const AuthContext = React.createContext(null);

type AuthProviderServices = {
	userId: string;
	token: string;
	isLoginPending: boolean;
	isLoginError: boolean;
	login: (credentials: LoginCredentials, redirectLocation?: string) => Promise<LoginResult>;
	logout: () => void;
	isAuthenticated: () => boolean;
};

// Local storage key constants
export const LOCAL_STORAGE_TOKEN_KEY = 'authToken';
export const LOCAL_STORAGE_USER_ID_KEY = 'userId';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [token, setToken] = useState<string>(() => localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY));
	const [userId, setUserId] = useState<string>(() => localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY));

	const history = useHistory();

	const { isLoading, isError, mutateAsync: apiLoginAsync } = useLogin();
	const { clearCache } = useClearCache();

	// Persist the token and userId to local storage
	useEffect(() => {
		if (token) {
			localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
		} else {
			localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
		}

		if (userId) {
			localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, userId);
		} else {
			localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY);
		}
	}, [token, userId]);

	const loginUser = (credentials: LoginCredentials, redirectLocation?: string) => {
		console.log('logging in');

		return apiLoginAsync(credentials, {
			onSuccess: (loginResult: LoginResult) => {
				console.log('login success!');
				setToken(loginResult.token);
				setUserId(`${loginResult.user?.id}`);

				if (redirectLocation) {
					history.push(redirectLocation);
				}
			}
		});
	};

	const logoutUser = () => {
		setToken(null);
		setUserId(null);

		clearCache();

		history.replace('/login');
	};

	// Simple for now and just checks if there is a token present in memory or not
	const isAuthenticated = (): boolean => {
		return !!token;
	};

	return (
		<AuthContext.Provider
			value={{
				token,
				userId,
				isLoginPending: isLoading,
				isLoginError: isError,
				login: loginUser,
				logout: logoutUser,
				isAuthenticated
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext<AuthProviderServices>(AuthContext);
};

export default AuthProvider;
